<script>

    import appConfig from "@/app.config";

    /**
     * Projects-grid component
     */
    export default {
        page: {
            title: "privacy policy",
            meta: [{name: "description", content: appConfig.description}]
        },
        name: 'PrivacyPolicy',
                mounted () {
            window.scrollTo(0, 0)
        }


    };
</script>

<template>
    <div>
        <section class="container py-landing text-center">
                <div class="row justify-content-center">
                    <div class="col-12 col-md-10 col-lg-9"><h1 class=" font-weight-bold color-white"> {{$t('privacy.header')}} </h1></div>
                </div>
        </section>
        <section class="general bg-color-blue-nav color-white">
            <div class="container py-landing">
                <div class="general-wrapper">
                    <div class="row justify-content-center">
                        <div class="col-12 font-size-15">
                            <p>{{$t('privacy.part1.p1')}}</p>
                            <p>{{$t('privacy.part1.p2')}}</p>
                            <p>{{$t('privacy.part1.p3')}}</p>
                           <h4 class="my-3"><strong>{{$t('privacy.part2.t')}}</strong></h4>
                            <p>{{$t('privacy.part2.p')}}</p>
                            <h4 class="my-3"><strong>{{$t('privacy.part3.t')}}</strong></h4>
                            <p>{{$t('privacy.part3.p1')}}</p>
                            <p>{{$t('privacy.part3.p2')}}</p>
                            <p>{{$t('privacy.part3.p3')}}</p>
                            <h4 class="my-3"><strong>{{$t('privacy.part4.t')}}</strong></h4>
                            <p>{{$t('privacy.part4.p')}}</p>
                            <ul class="m-4">
                                <li>{{$t('privacy.part4.l1')}}</li>
                                <li>{{$t('privacy.part4.l2')}}</li>
                                <li>{{$t('privacy.part4.l3')}}</li>
                                <li>{{$t('privacy.part4.l4')}}</li>
                                <li>{{$t('privacy.part4.l5')}}</li>
                                <li>{{$t('privacy.part4.l6')}}</li>
                            </ul>
                            <h4 class="my-3"><strong>{{$t('privacy.part5.t')}}</strong></h4>
                            <p>{{$t('privacy.part5.p')}}</p>
                            <h4 class="my-3"><strong>{{$t('privacy.part8.t')}}</strong></h4>
                            <p>{{$t('privacy.part8.p1')}}</p>
                            <p>{{$t('privacy.part8.p2')}}</p>
                            <h4 class="my-3"><strong>{{$t('privacy.part9.t')}}</strong></h4>
                            <p>{{$t('privacy.part9.p1')}}</p>
                            <ul class="m-4">
                                <li>{{$t('privacy.part9.l1')}}</li>
                                <li>{{$t('privacy.part9.l2')}}</li>
                                <li>{{$t('privacy.part9.l3')}}</li>
                            </ul>
                            <p>{{$t('privacy.part9.p2')}} <a href="mailto:contact@crisi5.com"> {{$t('privacy.contact_us')}}</a>.</p>

                            <h4 class="my-3"><strong>{{$t('privacy.part10.t')}}</strong></h4>
                            <p>{{$t('privacy.part10.p1')}}</p>
                            <ul class="m-4">
                               <li>{{$t('privacy.part10.l1')}}</li>
                               <li>{{$t('privacy.part10.l2')}}</li>
                               <li>{{$t('privacy.part10.l3')}}</li>
                               <li>{{$t('privacy.part10.l4')}}</li>
                               <li>{{$t('privacy.part10.l5')}}</li>
                               <li>{{$t('privacy.part10.l6')}}</li>
                            </ul>
                            <p>{{$t('privacy.part10.p2')}}  <a  href="mailto:contact@crisi5.com">{{$t('privacy.contact_us')}}.</a></p>
                          <h4 class="my-3"><strong>{{$t('privacy.part11.t')}}</strong></h4>
                          <p>{{$t('privacy.part11.p1')}}</p>
                          <p>{{$t('privacy.part11.p2')}}</p>
                          <p>{{$t('privacy.part11.p3')}} <a :href="'mailto:' + $t('privacy.part11.email')"> {{$t('privacy.part11.email')}} </a> {{$t('privacy.part11.p4')}}</p>
                          <h4 class="my-3"><strong>{{$t('privacy.part12.t')}}</strong></h4>
                            <p>{{$t('privacy.part12.p1')}} <a :href="'mailto:' + $t('privacy.part12.email')"> {{$t('privacy.part12.email')}} </a> {{$t('privacy.part12.p2')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- end ro w -->
    </div>
</template>
<style scoped>
    .py-landing {
        padding-top: 5rem;
    }
    section.general .general-wrapper {
        max-width: 43.5rem;
        margin: 0 auto;
    }
    p {
        color: #a4b1cd;
        line-height: 30px;
    }
   li{
       margin-bottom: 8px;
   }

</style>